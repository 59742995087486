import React from 'react';

const GoogleMap = () => {
    return (
        <>
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3364.8399666154!2d74.50278761515764!3d32.503706205178005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391eea678a467a19%3A0xbb02ba3d4c7034d5!2sKarigar%20Web%20Solutions!5e0!3m2!1sen!2s!4v1659146734419!5m2!1sen!2s"
                width="100%" height="450" allowFullScreen="" loading="lazy"
                referrerPolicy="no-referrer-when-downgrade" title="karigar's location"></iframe>
        </>
    )
}

export default GoogleMap;  