import React from 'react';

const Footer = () => {
    const currentYear = new Date().getFullYear();
    return (
        <footer className="footer-area bg-f7fafd">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12 col-md-12">
                        <div className="copyright-area">
                            <p>Copyright &copy; {currentYear}. All rights reserved by <a href="https://karigar.pk/" target="_blank" rel="noreferrer">Karigar Web Solutions</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )

}

export default Footer; 